<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100">
            <div class="row d-flex justify-content-center">
                <div class="col-10">
                    <div class="row padding-b">
                        <div class="col-sm-12 col-md-6 pb-10px">
                            <img src="/assets/images/landing-page/img-creditcard.jpg" width="100%" height="100%" class="img">
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <h2 class="h2 mb-10px">
                                1. เปิดบัญชี iTAX bnk
                            </h2>
                            <div class="p2 mb-20px sub-text text-gray-medium">
                                หากคุณได้รับคัดเลือกจากโครงการ iTAX bnk
                                เราจะนัดหมายวันให้คุณเดินทางมาเปิดบัญชีธนาคารที่ธนาคารกรุงไทย สำนักงานใหญ่
                                และแนะนำวิธีการใช้งานเบื้องต้น ซึ่งในวันเปิดบัญชีคุณจะได้รับเลขบัญชีธนาคาร บัตรเดบิต
                                และบัญชีใช้งานแอป iTAX bnk ได้ทันที
                            </div>
                        </div>
                    </div>
                    <div class="row padding-b">
                        <div class="col-sm-12 col-md-6 pb-10px">
                            <img src="/assets/images/landing-page/img-buyonline.jpg" width="100%" height="100%" class="img">
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <h2 class="h2 mb-10px">
                                2. ซื้อขายตามปกติ
                            </h2>
                            <div class="p2 mb-20px sub-text text-gray-medium">
                                พ่อค้าแม่ค้าออนไลน์ยังคงใช้ชีวิตตามปกติ
                                เพียงแค่ทุกครั้งที่จะรับเงินจากการขายของให้ใช้บัญชี iTAX bnk รับเงิน
                                และทุกครั้งที่ซื้อของเข้าร้านหรือมีรายจ่ายเกี่ยวกับธุรกิจก็ให้ใช้บัญชี iTAX bnk
                                จ่ายเงินเช่นกัน
                                เมื่อรายรับ-จ่ายที่เกี่ยวข้องกับธุรกิจทั้งหมดรวมอยู่ในบัญชีธนาคารเดียวแล้ว บัญชี iTAX
                                bnk ก็จะจัดการภาษีได้อย่างถูกต้อง ทั้งนี้ บัญชี iTAX bnk
                                จะดูแลความถูกต้องได้เฉพาะรายการเงินเข้า-ออกในบัญชีธนาคาร iTAX bnk เท่านั้น
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 pb-10px">
                            <img src="/assets/images/landing-page/img-cellpcheck.jpg" width="100%" height="100%" class="img">
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <h2 class="h2 mb-10px">
                                3. ทำตามที่ app บอก
                            </h2>
                            <div class="p2 mb-20px sub-text text-gray-medium">
                                iTAX bnk อยู่ในความดูแลของโครงการทดสอบนวัตกรรมภาษี Tax Sandbox
                                รายการเงินเข้า-ออกจะแสดงผลบนแอป iTAX bnk
                                เพื่อประมวลผลค่าภาษีที่เกี่ยวข้องให้พ่อค้าแม่ค้าออนไลน์ได้ตรวจสอบก่อนยื่นภาษีและชำระภาษี
                                ซึ่งแอปจะแจ้งสิ่งที่ต้องทำ แค่ทำตามที่แอปบอกทุกอย่างก็เรียบร้อย
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
