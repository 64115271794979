<div class="card-md">
  <div class="row d-flex justify-content-center pointer" *ngFor="let item of items">
    <div class="col mb-10px position-relative d-flex justify-content-center">
      <div class="card-mobile position-relative">
        <div class="ribbon ribbon-top-right" *ngIf="item.recommended"><span>ยอดนิยม</span>
        </div>
        <div class="wrap-img">
          <img src="{{item.img}}" width="60" height="60">
        </div>
        <div class="wrap-content">
          <span class="content-title">{{item.title}}</span>
          <span class="content-detail">{{item.details}}</span>
          <span class="content-subDetail">
                  {{item.subDetail}}
              </span>
        </div>
      </div>
    </div>
  </div>
</div>
