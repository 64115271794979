export interface Item {
  title: string;
  details: string;
  subDetail: string;
  recommended: boolean;
  img: string;
}

export const CardItems: Item[] = [{
  title: 'โปรแกรมเงินเดือน',
  details: '฿1,000 ต่อเดือน',
  subDetail: 'พนักงานไม่เกิน 20 คนต่อบริษัท',
  img: '/assets/images/landing-page/man.png',
  recommended: false
}, {
  title: 'โปรแกรมเงินเดือน',
  details: '฿50 ต่อคน ต่อเดือน',
  subDetail: 'พนักงาน 20 คนขึ้นไปต่อบริษัท',
  img: '/assets/images/landing-page/money.png',
  recommended: true
}, {
  title: 'โปรแกรมเงินเดือน',
  details: 'ติดต่อขอราคาพิเศษ',
  subDetail: 'พนักงาน 100 คนขึ้นไปต่อบริษัท',
  img: '/assets/images/landing-page/coinstacks.png',
  recommended: false
}];
