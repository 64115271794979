import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {SocialTagsService} from "../../../core/services/social-tags-service.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-paystation',
  templateUrl: './paystation.component.html',
  styleUrls: ['./paystation.component.scss']
})
export class PaystationComponent implements OnInit {
  readonly tagSocialTitle: string = 'iTAX paystation โปรแกรมเงินเดือน';
  readonly tagSocialDescription: string = 'โปรแกรมเงินเดือน ภาษี ประกันสังคม สลิปเงินเดือน อัพเดตตามกฎหมายใหม่ล่าสุด - iTAX';
  readonly urlPrefix: string = environment.mainBaseUrl;

  constructor(
    private meta: Meta,
    private title: Title,
    private tagsService: SocialTagsService
  ) {
    this.title.setTitle('iTAX paystation โปรแกรมเงินเดือน payroll');
    this.meta.updateTag({
      name: 'title',
      content: 'iTAX paystation โปรแกรมเงินเดือน payroll'
    });
    this.meta.updateTag({
      name: 'description',
      content: 'โปรแกรมเงินเดือน คำนวณภาษี ประกันสังคม พร้อมไฟล์ยื่นออนไลน์ และสลิปเงินเดือนอัตโนมัติ'
    });
    this.tagsService.setTitleAndTags({
      tagSocialDescription: this.tagSocialDescription,
      tagSocialTitle: this.tagSocialTitle,
      urlPrefix: this.urlPrefix
    });
  }

  ngOnInit(): void {
  }

  gotoAppPlay(): void {
    window.open('//bit.ly/2ugrUeM', '_blank');
  }

  gotoApp(): void {
    window.open('//apple.co/2sPConn', '_blank');
  }

}
