<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100">
            <div class="row wrapper">
                <div class="col body pointer" (click)="goto('bnk/')">
                    <div class="row d-flex justify-content-center">
                        <div class="col-10">
                            <h2 class="h2 w-100 text-center mb-10px">iTAX bnk</h2>
                            <p class="p2 text-center sub-text mb-20px text-gray-medium">
                                บัญชีธนาคารสำหรับแม่ค้าออนไลน์ จัดการภาษีให้อัตโนมัติ
                            </p>
                            <div class="d-flex justify-content-center align-items-center pointer">
                                <a class="text-center font-primary link">ดูเพิ่ม</a>
                                <img src="/assets/images/ico-arrow-right-green.svg" width="24" height="24" alt="arrow">
                            </div>
                        </div>
                    </div>
                    <div class="wrap-img">
                        <picture>
                            <source type="image/webp" srcset="/assets/images/landing-page/img-iTAXbnk.webp">
                            <source type="image/jpeg" srcset="/assets/images/landing-page/img-iTAXbnk.jpg">
                            <img srcset="/assets/images/landing-page/img-iTAXbnk.jpg" alt="iTAXbnk">
                        </picture>
                        <!-- <figure class="bg-img bnk"></figure> -->
                    </div>
                </div>
                <div class="col body pointer" (click)="goto('sme/')">
                    <div class="row d-flex justify-content-center">
                        <div class="col-10">
                            <h2 class="h2 w-100 text-center mb-10px">iTAX sme</h2>
                            <p class="p2 text-center sub-text mb-20px text-gray-medium">
                                บริการที่ปรึกษาภาษีธุรกิจแบบครบวงจรเพื่อเจ้าของธุรกิจ SME
                            </p>
                            <div class="d-flex justify-content-center align-items-center pointer">
                                <a class="text-center font-primary link">ดูเพิ่ม</a>
                                <img src="/assets/images/ico-arrow-right-green.svg" width="24" height="24" alt="arrow">
                            </div>
                        </div>
                    </div>
                    <div class="wrap-img">
                        <picture>
                            <source type="image/webp" srcset="/assets/images/landing-page/img-iTAXsme.webp">
                            <source type="image/jpeg" srcset="/assets/images/landing-page/img-iTAXsme.jpg">
                            <img srcset="/assets/images/landing-page/img-iTAXsme.jpg" alt="iTAXsme">
                        </picture>
                        <!-- <figure class="bg-img sme"></figure> -->
                    </div>
                </div>
            </div>
            <div class="row pt-10px wrapper">
                <!--            <div class="row wrapper">-->
                <div class="col body pointer" (click)="goto('media/')">
                    <div class="row d-flex justify-content-center">
                        <div class="col-10">
                            <h2 class="h2 w-100 text-center mb-10px">ข่าวภาษี</h2>
                            <p class="p2 text-center sub-text mb-20px text-gray-medium">
                                เจาะทุกประเด็นที่ผู้เสียภาษีควรรู้
                            </p>
                            <div class="d-flex justify-content-center align-items-center pointer">
                                <span class="text-center font-primary link link-span">อ่านข่าว</span>
                                <img src="/assets/images/ico-arrow-right-green.svg" width="24" height="24" alt="arrow">
                            </div>
                        </div>
                    </div>
                    <div class="wrap-img">
                        <picture>
                            <source type="image/webp" srcset="/assets/images/landing-page/img-iTAXmedia.webp">
                            <source type="image/jpeg" srcset="/assets/images/landing-page/img-iTAXmedia.jpg">
                            <img srcset="/assets/images/landing-page/img-iTAXmedia.jpg" alt="iTAXmedia">
                        </picture>
                        <!-- <figure class="bg-img media"></figure> -->
                    </div>
                </div>
                <div class="col body pointer" (click)="goto('pedia/')">
                    <div class="row d-flex justify-content-center">
                        <div class="col-10">
                            <h2 class="h2 w-100 text-center mb-10px">ความรู้ภาษี</h2>
                            <p class="p2 text-center sub-text mb-20px text-gray-medium">
                                เข้มข้มเหมือนตำรา ย่อยง่ายเหมือนนิทาน
                            </p>
                            <div class="d-flex justify-content-center align-items-center pointer">
                                <span class="text-center font-primary link link-span">เริ่มอ่าน</span>
                                <img src="/assets/images/ico-arrow-right-green.svg" width="24" height="24" alt="arrow">
                            </div>
                        </div>
                    </div>
                    <div class="wrap-img">
                        <picture>
                            <source type="image/webp" srcset="/assets/images/landing-page/img-iTAXpedia.webp">
                            <source type="image/jpeg" srcset="/assets/images/landing-page/img-iTAXpedia.jpg">
                            <img srcset="/assets/images/landing-page/img-iTAXpedia.jpg" alt="iTAXpedia">
                        </picture>
                        <!-- <figure class="bg-img pedia"></figure> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
