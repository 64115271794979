<div class="box">
  <div class="itax-body">
    <div class="container position-relative w-100 h-100">
      <div class="row d-flex justify-content-center">
        <div class="col-12">
          <h2 class="h2 w-100 text-center mb-10px">ภาษีควรเป็นเรื่องง่าย</h2>
          <p class="p2 text-center sub-text mb-20px text-gray-medium">
            iTAX เกิดจากงานวิจัยปริญญาเอกด้านกฎหมายภาษี ด้วยความเชื่อว่าผู้เสียภาษีทุกคน คือ ฮีโร่ตัวจริงของประเทศ
            iTAX จึงพัฒนาเทคโนโลยีที่ทำให้ภาษีเป็นเรื่องง่ายที่สุดสำหรับทุกคน
            เพราะนี่คือสิ่งที่ผู้เสียภาษีสมควรได้รับ
          </p>
          <div class="d-flex justify-content-center align-items-center pointer">
            <a class="text-center font-primary link" href="{{env.mainBaseUrl}}/pedia/iTAX/">ความเป็นมาของ iTAX</a>
            <img src="/assets/images/ico-arrow-right-green.svg" width="24" height="24" alt="arrow">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
