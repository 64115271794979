<div class="position-relative w-100 padding">

  <div class="section-hero">
    <div class="container text-center">
      <div class="content">
        <h1 class="h1 mb-0 text-primary">iTAX paystation โปรแกรมเงินเดือน</h1>
        <h1 class="h1 mb-8px">สำหรับยุค Digital Transformation</h1>
        <p class="p1 mb-24px text-gray-medium">
          โปรแกรม payroll รูปแบบใหม่ มาพร้อมระบบ Self-service ลดงานและคืนเวลาให้ HR พร้อมเชื่อมต่อการคำนวณภาษีและการเงินส่วนบุคคลสำหรับพนักงานในรูปแบบที่ไม่เคยมีใครทำได้มาก่อน
        </p>
        <div class="d-flex justify-content-center">
          <a href="tel:062-486-9787">
            <button class="btn btn-primary btn-call">โทร 062-486-9787</button>
          </a>
        </div>
      </div>
      <div class="wrap-image">
        <picture>
          <source type="image/webp" srcset="/assets/images/landing-page/paystation/paystation-hero.webp">
          <source type="image/jpeg" srcset="/assets/images/landing-page/paystation/paystation-hero.png">
          <img srcset="/assets/images/landing-page/paystation/paystation-hero.png" alt="paystation landing">
        </picture>
      </div>
    </div>
  </div>

  <div class="section-media">
    <div class="container container-narrow text-center">
      <p class="p1 wrap-text">ได้รับความเชื่อมั่นจากองค์กรธุรกิจด้าน Creative และ Technology</p>
      <div class="wrap-column">
        <div class="col-item">
          <div class="wrap-image">
            <picture>
              <source type="image/webp" srcset="/assets/images/landing-page/paystation/img-main-media-1.webp">
              <source type="image/jpeg" srcset="/assets/images/landing-page/paystation/img-main-media-1.png">
              <img srcset="/assets/images/landing-page/paystation/img-main-media-1.png" alt="paystation media">
            </picture>
          </div>
        </div>
        <div class="col-item">
          <div class="wrap-image">
            <picture>
              <source type="image/webp" srcset="/assets/images/landing-page/paystation/img-main-media-2.webp">
              <source type="image/jpeg" srcset="/assets/images/landing-page/paystation/img-main-media-2.png">
              <img srcset="/assets/images/landing-page/paystation/img-main-media-2.png" alt="paystation media">
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-feature">
    <div class="container container-narrow">

      <div class="box-feature box-feature-blue">
        <div class="content">
          <div class="wrap-icon">
            <img srcset="/assets/images/landing-page/paystation/ico-money-blue.svg" width="64" />
          </div>
          <h2 class="h2 mb-8px">จัดการ Payroll อัตโนมัติ</h2>
          <p class="p2 font-body text-gray-medium mb-16px">
            เมื่อคุณคอนเฟิร์ม payroll ระบบจะเตรียมเอกสารและ file ยื่นออนไลน์ต่างๆ ทั้งหมดให้ทันที บอกลาการ copy/paste ข้อมูลเดิมซ้ำๆ เพื่อทำเอกสารแยกตามรายการ
          </p>
          <h4 class="h4 mb-8px">เอกสาร และไฟล์ต่างๆ ที่จะได้รับ:</h4>
          <ul class="list-item mb-0">
            <li><i class="fas fa-check-circle text-primary"></i>ไฟล์โอนเงินธนาคาร</li>
            <li><i class="fas fa-check-circle text-primary"></i>ประกันสังคม (สปส.1-10, กท 20ก)</li>
            <li><i class="fas fa-check-circle text-primary"></i>ภาษี (ภ.ง.ด. 1, ภ.ง.ด. 1ก)</li>
            <li><i class="fas fa-check-circle text-primary"></i>สลิปเงินเดือน / ใบ 50 ทวิ</li>
            <li><i class="fas fa-check-circle text-primary"></i>และอื่นๆ ที่เกี่ยวข้องกับพนักงาน</li>
          </ul>
        </div>
        <div class="wrap-image">
          <picture>
            <source type="image/webp" srcset="/assets/images/landing-page/paystation/paystation-payroll.webp">
            <source type="image/jpeg" srcset="/assets/images/landing-page/paystation/paystation-payroll.png">
            <img srcset="/assets/images/landing-page/paystation/paystation-payroll.png" alt="paystation payroll">
          </picture>
        </div>
      </div>

      <div class="box-feature box-feature-green">
        <div class="content">
          <div class="wrap-icon">
            <img srcset="/assets/images/landing-page/paystation/ico-employee-green.svg" width="64" />
          </div>
          <h2 class="h2 mb-8px">Self-service กระจายงาน</h2>
          <p class="p2 font-body text-gray-medium mb-16px">
            ระบบ Self-service จะช่วยกระจายงานให้พนักงานจัดการตนเอง เพื่อแบ่งเบางาน HR เช่น:
          </p>
          <ul class="list-item mb-16px">
            <li><i class="fas fa-check-circle text-primary"></i>ดาวน์โหลด สลิปเงินเดือน / ใบ 50 ทวิ</li>
            <li><i class="fas fa-check-circle text-primary"></i>แก้ไขข้อมูลส่วนตัว</li>
            <li><i class="fas fa-check-circle text-primary"></i>แจ้งสิทธิลดหย่อนประจำปี</li>
            <li><i class="fas fa-check-circle text-primary"></i>ลางาน</li>
            <li><i class="fas fa-check-circle text-primary"></i>และอื่นๆ อีกมายมาย</li>
          </ul>
          <p class="p2 font-body text-gray-medium mb-0">
            ด้าน HR เพียงอนุมัติหรือปฏิเสธคำขอ ระบบก็จะบันทึกข้อมูลและประมวลผลให้อัตโนมัติ เพิ่มความแม่นยำของข้อมูลพนักงานและลดภาระงานของ HR ได้อย่างมีนัยสำคัญ
          </p>
        </div>
        <div class="wrap-image">
          <picture>
            <source type="image/webp" srcset="/assets/images/landing-page/paystation/paystation-selfservice.webp">
            <source type="image/jpeg" srcset="/assets/images/landing-page/paystation/paystation-selfservice.png">
            <img srcset="/assets/images/landing-page/paystation/paystation-selfservice.png" alt="paystation selfservice">
          </picture>
        </div>
      </div>

      <div class="box-feature box-feature-orange">
        <div class="content">
          <div class="wrap-icon">
            <img srcset="/assets/images/landing-page/paystation/ico-timer-orange.svg" width="64" />
          </div>
          <h2 class="h2 mb-8px">ลงเวลาเข้างานที่ไหนก็ได้</h2>
          <p class="p2 font-body text-gray-medium mb-16px">
            ด้วยระบบ Time Attendance รองรับการลงเวลาเข้า-ออกงานผ่าน app ได้ด้วยตัวเอง จึงเหมาะสำหรับธุรกิจที่เริ่มเปลี่ยนแนวทางการทำงานเป็น Work from Home
          </p>
          <p class="p2 font-body text-gray-medium mb-0">
            หากเป็นกิจการที่พนักงานต้องทำงาน on-site ก็ไม่ต้องเสียเวลาต่อคิวที่เครื่องตอกบัตร เพราะระบบลงเวลาเข้างานของ iTAX paystation สามารถใช้ GPS ตรวจสอบพิกัดเมื่อลงเวลาได้ว่าอยู่ที่บริษัทหรือไม่ พร้อมระบบตรวจสอบใบหน้า หมดปัญหาการตอกบัตรแทนกัน
          </p>
        </div>
        <div class="wrap-image">
          <picture>
            <source type="image/webp" srcset="/assets/images/landing-page/paystation/paystation-timeattendance.webp">
            <source type="image/jpeg" srcset="/assets/images/landing-page/paystation/paystation-timeattendance.png">
            <img srcset="/assets/images/landing-page/paystation/paystation-timeattendance.png" alt="paystation time attendance">
          </picture>
        </div>
      </div>

    </div>
  </div>
  
  <div class="section-app">
    <div class="container">
      <div class="wrap-column">
        <div class="col-item position-relative col-image">
          <div class="wrap-image">
            <picture>
              <source type="image/webp" srcset="/assets/images/landing-page/paystation/app-preview.webp">
              <source type="image/jpeg" srcset="/assets/images/landing-page/paystation/app-preview.png">
              <img srcset="/assets/images/landing-page/paystation/app-preview.png" alt="paystation app preview">
            </picture>
          </div>
        </div>
        <div class="col-item">
          <div class="content">
            <h2 class="h2 mb-8px">Sync กับแอปวางแผนภาษี</h2>
            <p class="p2 font-body text-gray-medium mb-24px">
              iTAX paystation อาศัยข้อได้เปรียบจากการเชื่อมต่อกับแอป iTAX 
              ซึ่งเป็น app คำนวณภาษีสำหรับพนักงานได้อย่างเต็มรูปแบบ โดย app จะดึงข้อมูลรายได้, 
              ภาษีหัก ณ ที่จ่าย, ประกันสังคม และกองทุนสำรองเลี้ยงชีพ จาก iTAX paystation 
              มาคำนวณภาษีให้พนักงานทุกคนแบบ Real-time พร้อมวางแผนภาษีด้วยตัวเองได้อย่างแม่นยำ
            </p>
            <div class="wrap-store-icon d-flex">
              <div class="btn-store pointer" (click)="gotoAppPlay()">
                <itax-icon [name]="'playstore'" [width]="'126px'" [height]="'40px'"></itax-icon>
              </div>
              <div class="btn-store pointer" (click)="gotoApp()">
                <itax-icon [name]="'appstore'" [width]="'126px'" [height]="'40px'"></itax-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-price">
    <div class="container">
      <h2 class="h2 text-center mb-8px">ค่าบริการ</h2>
      <p class="p2 font-body text-gray-medium text-center mb-24px">เลือก Package ตามองค์กรของคุณ</p>
      <app-price></app-price>
    </div>
  </div>

  <div class="section-upgrade">
    <div class="container container-narrow">
      <div class="box-upgrade">
        <div class="content text-center">
          <h2 class="h2 mb-16px">Upgrade today</h2>
          <p class="p1 mb-32px">อัปเกรดจากระบบเก่ามาเป็น iTAX paystation เพื่อให้งาน payroll สิ้นเดือนนี้สบายกว่าที่เคย</p>
          <a href="tel:062-486-9787">
            <button class="btn btn-primary btn-call">โทร 062-486-9787</button>
          </a>
        </div>
      </div>
    </div>
  </div>
  
</div>
