import {Component, OnInit} from '@angular/core';
import {CardItems} from './price';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {

  items = CardItems;

  constructor() {
  }

  ngOnInit(): void {
  }

}
