import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './main.component';
import {EventComponent} from '../event/event.component';
import {AppComponent} from '../app/app.component';
import {SmeComponent} from '../sme/sme.component';
import {BnkComponent} from '../bnk/bnk.component';
import {PaystationComponent} from '../paystation/paystation.component';
import {JobsComponent} from '../jobs/jobs.component';

const routes: Routes = [{
  path: '',
  component: MainComponent,
  pathMatch: 'full'
}, {
  path: 'event',
  component: EventComponent,
  children: [
    {
      path: '',
      loadChildren: () => import('../event/event.module').then(m => m.EventModule)
    }
  ]
}, {
  path: 'app',
  component: AppComponent,
  children: [
    {
      path: '',
      loadChildren: () => import('../app/app.module').then(m => m.AppModule)
    }
  ]
}, {
  path: 'sme',
  component: SmeComponent,
  children: [
    {
      path: '',
      loadChildren: () => import('../sme/sme.module').then(m => m.SmeModule)
    }
  ]
}, {
  path: 'bnk',
  component: BnkComponent,
  children: [
    {
      path: '',
      loadChildren: () => import('../bnk/bnk.module').then(m => m.BnkModule)
    }
  ]
}, {
  path: 'paystation',
  component: PaystationComponent,
  children: [
    {
      path: '',
      loadChildren: () => import('../paystation/paystation.module').then(m => m.PaystationModule)
    }
  ]
}, {
  path: 'jobs',
  component: JobsComponent,
  children: [
    {
      path: '',
      loadChildren: () => import('../jobs/jobs.module').then(m => m.JobsModule)
    }
  ]
}, {
  path: '**',
  redirectTo: '/'
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
