<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100">
            <div class="row d-flex justify-content-center">
                <div class="col">
                    <h2 class="h2 w-100 text-center mb-10px">คำนวณภาษี</h2>
                    <p class="p2 text-center sub-text mb-20px text-gray-medium">
                        App คำนวณ และวางแผนภาษีเงินได้บุคคลธรรมดา ได้เงินคืนภาษีสูงสุด โดยไม่ต้องรู้ภาษี
                    </p>
                    <div class="d-flex justify-content-center mb-20px">
                        <div class="pr-15px pointer" (click)="gotoAppPlay()">
                            <itax-icon [name]="'playstore'" [width]="'120px'" [height]="'38px'"></itax-icon>
                        </div>
                        <div class="pointer" (click)="gotoApp()">
                            <itax-icon [name]="'appstore'" [width]="'120px'" [height]="'38px'"></itax-icon>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center pointer">
                        <a class="text-center font-primary link" href="//bit.ly/itaxapp">ดาวน์โหลด iTAX</a>
                        <img src="/assets/images/ico-arrow-right-green.svg" width="24" height="24" alt="arrow">
                    </div>
                </div>
            </div>
            <div class="wrap-img">
                <picture>
                    <source type="image/webp" srcset="/assets/images/landing-page/img-app-layers.webp">
                    <source type="image/jpeg" srcset="/assets/images/landing-page/img-app-layers.jpg">
                    <img srcset="/assets/images/landing-page/img-app-layers.jpg" alt="layers">
                </picture>
                <!-- <figure class="bg-img"></figure> -->
            </div>
        </div>
    </div>
</div>
