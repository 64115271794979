<div class="box">
  <div class="itax-body">
    <div class="container position-relative w-100 h-100">
      <div class="row d-flex justify-content-center">
        <div class="col-10">
          <h2 class="h2 w-100 text-center mb-10px">iTAX Culture</h2>
          <p class="p2 text-center sub-text text-gray-medium pb-60px">Most of the jobs at iTAX are challenging. When
            things are not going well, that is when you need
            perseverance more than ever. But the good news is we have a strong and unique culture that might fits you
            well. Here we go.</p>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-10">
          <div class="row pb-30px">
            <div class="col-sm-12 col-md-6 pb-10px">
              <img src="/assets/images/landing-page/iTAXteam1.jpg" width="100%">
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="h2 mb-10px">
                Flat
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">
                One team. No boss. Only collaboration.
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">Yes, you are your own boss. You are the one who
                decides
                what you want to do. Then, we collaborate with team members and get through challenges together. Since
                iTAX is a flat organization, you can talk directly to anyone at iTAX, including CEO, at anytime.
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">We believe in direct communication that conveys clear
                messages. Disagreement is fine. Differences are not bad. Discussions are encouraged. Talking behind
                someone’s back is not part of our culture.
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">No one deserves termination merely because they tell the
                truth for the best interest of the team.
              </div>
            </div>
          </div>
          <div class="row pb-30px">
            <div class="d-sm-block d-md-none d-lg-none col pb-10px">
              <img src="/assets/images/landing-page/iTAXteam2.jpg" width="100%">
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="h2 mb-10px">
                Flexible
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">
                Bring the best of you in your own way.
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">We know everyone is unique. And we know your
                contributions will make great impacts to taxpayers, iTAX, and our country. So flexibility is what we
                highly value.
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">At iTAX, you have freedom to get your job done in the
                style that fits you best. How you do it, when you do it, where you do it, is totally up to you. You may
                work at night and sleep during the day. You just have to get your work done.
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">We believe in working smart and waking up refreshed. So
                you have autonomy over your time, your task, and your technique.
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">In short, we trust you.
              </div>
            </div>
            <div class="d-none d-md-block d-lg-block col-md-6">
              <img src="/assets/images/landing-page/iTAXteam2.jpg" width="100%">
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6 pb-10px">
              <img src="/assets/images/landing-page/iTAXteam3.jpg" width="100%">
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="h2 mb-10px">
                Focus
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">
                Less is more.
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">iTAX is fortunate to receive various ideas and
                opportunities from users, business partners, and stakeholders. When it comes to ideas and opportunities,
                however, deciding what not to do is as important as deciding what to do. Especially, when resources are
                limited, you need to focus on what matters the most and say no to other hundreds of good opportunities.
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">To help you understand better, at iTAX, we focus on
                ideas and opportunities that consume less resources and get better results to our business and society.
                But doing less is sometimes more sophisticated than doing more. These challenges encourage you to come
                up with something different, creative, or even crazy, which is ok!
              </div>
              <div class="p2 mb-20px sub-text text-gray-medium">iTAX has a clear focus on making tax simple to
                taxpayers. But this task is not simple since we focus on a long-term vision rather than a short-term
                effect.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
